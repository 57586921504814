.partner_finding_title {
  font-size: 60px;
  color: #0067c3;
}
.partner_finding_subtitle {
  color: #515154;
}
.show_more_card_partner_finding {
  padding: 30px 0 40px 0;
}
.partner_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
@media screen and (max-width: 1202px) {
  .partner_header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .partner_header_wrapper {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .partner_header_wrapper h1 {
    font-size: 40px;
  }
  .partner_header_wrapper h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .partner_header_wrapper {
    text-align: center;
  }
  .partner_header_wrapper h1 {
    font-size: 40px;
  }
  .partner_header_wrapper h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .partner_header_wrapper h1 {
    font-size: 30px;
  }
  .partner_header_wrapper h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}
