.company_detail_contact_tab_section {
  background-color: #f7f7f7;
  padding: 20px 0 20px 0;
}
.company_detail_contact_tab .company_detail_contact_tab_header {
  display: flex;
}
.company_detail_contact_tab .company_detail_contact_tab_header > div {
  width: calc(100% / 3);
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  color: #acacac;
  cursor: pointer;
}
.company_detail_contact_tab .company_detail_contact_tab_header > div.active {
  color: #175a95;
}
.company_detail_contact_tab_indicator {
  position: relative;
  left: 0;
  height: 2px;
  top: 20px;
  background-color: #175a95;
  width: calc(calc(100% / 3) - 80px);
  transition: all 500ms ease-in-out;
}
.company_detail_contact_tab_body {
  margin-top: 50px;
  margin-bottom: 50px;
}
.company_detail_contact_tab_body > div {
  display: none;
  transform: scale(0.3);
  transition: opacity 500ms ease-in-out 0ms, transform 500ms ease-in-out 0ms;
}
.company_detail_contact_tab_body > div.active {
  display: block;
  color: #000;
  transform: scale(1);
}
.company_detail_contact_tab_body_content h3 {
  text-transform: uppercase;
  font-weight: 600;
}
.company_detail_contact_tab_body_content h4 {
  font-size: 32px;
  font-weight: 400;
}
.company_detail_paragraph {
  margin-top: 50px;
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .company_detail_contact_tab .company_detail_contact_tab_header > div {
    font-size: 15px;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
  .company_detail_contact_tab_body_content h3 {
    font-size: 20px;
  }
  .company_detail_contact_tab_body_content h4 {
    font-size: 15px;
  }
}
