.CardMenu {
  padding-top: 65px;
  padding-bottom: 70px;
}

.CardMenu h1 {
  font-size: 55px;
  font-weight: 600;
  color: #000;
}

.CardMenu h2 {
  font-size: 35px;
  color: #2d3691;
  font-family: "Maven Pro", sans-serif !important;
  font-weight: 600;
}

.CardMenuChoice {
  border-radius: 30px !important;
  box-shadow: 0px 0px 2px 0px;
}
.CardMenuChoice:hover {
  cursor: pointer;
  transform: translateY(3px);
  transition: transform 0.5s ease-in-out;
}
.CardMenuChoiceImg {
  height: 100%;
  border-radius: 30px 30px 0 0 !important;
}
.CardMenuBody .h5 {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 28px !important;
  color: #0047f5;
}
.CardMenuBody .h6 {
  margin-left: 15px;
  margin-top: 8px;
  font-size: 34px !important;
  font-weight: 600;
  color: #000;
}
.sectionCardMenu {
  margin-top: 50px !important;
}
.sectionCardMenu .col {
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .CardMenuHeader {
    text-align: center;
  }
  .CardMenuHeader h1 {
    font-size: 40px;
  }
  .CardMenuHeader h2 {
    font-size: 25px;
  }
  .CardMenuBody .card-title {
    font-size: 20px !important;
  }
  .CardMenuBody .card-subtitle {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 768px) {
  .CardMenuHeader {
    text-align: center;
  }
  .CardMenuHeader h1 {
    font-size: 40px;
  }
  .CardMenuHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .CardMenuHeader h1 {
    font-size: 30px;
  }
  .CardMenuHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}
