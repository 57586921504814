@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Maven+Pro&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Maven+Pro&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Font Thai Kanit*/
/*Font English Roboto*/
* {
  font-family: "Lobster", cursive;
  font-family: "Maven Pro", sans-serif;
  font-family: "Prompt", sans-serif;
}

body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background-color: #f7f7f7;
}
.btn-primary {
  color: #fff;
  background-color: #341e97 !important;
  border-color: #341e97 !important;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1335px !important;
}

@media only screen and (max-width: 1200px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 960px !important;
  }
}
@media only screen and (max-width: 991px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 720px !important;
  }
}
@media only screen and (max-width: 767px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 540px !important;
  }
}
@media only screen and (max-width: 575px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 575px !important;
  }
}

.footer {
  margin-top: 80px;
  padding: 30px 0 30px 0;
  background-color: #f5f5f5;
}
.footer_wrapper {
  padding: 30px 0 30px 0;
}
.footer img {
  width: 200px;
  margin: 0 auto;
}
.footer_wrapper h5 {
  font-size: 16px;
  color: #78706f;
}
.sub_footer_detail {
  margin-top: 24px;
}
.icon_and_copyright {
  display: flex;
}
.icon_footer {
  font-size: 28px;
}
.icon_footer svg {
  margin-right: 15px;
}
.icon_footer svg:nth-child(1) {
  color: #1977f3;
}
.icon_and_copyright h5 {
  align-self: center;
  margin: -5px 0 0 5px;
}
.line_footer {
  width: 2px;
  height: 15px;
  background-color: #7392bb;
  margin-top: 10px;
}
.footer_detail {
  border-left: 1px solid #d3d3d3;
}
.logo_footer {
  text-align: center;
}
.footer_detail_wrapper {
  margin-left: 15px;
}
@media screen and (max-width: 1270px) {
}
@media only screen and (max-width: 991px) {
  .logo_footer {
    margin-bottom: 20px;
  }
  .footer_wrapper {
    text-align: center;
  }
  .footer_detail {
    border-left: none;
  }
  .icon_and_copyright {
    display: block;
  }
  .icon_and_copyright .icon_footer {
    margin-bottom: 5px;
  }
  .line_footer {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}

.navBarMenu {
  font-size: 22px;
  font-weight: 600;
}
.navBarMenu .nav-link {
  padding: 20px 24px 20px 24px !important;
}
.navBarMenu .logo {
  border-left: 6px solid #2d3691;
  padding-left: 14px;
  width: 225px;
}
.tnijobfairmenu {
  animation: color-change 2s infinite !important;
}
@keyframes color-change {
  14.28% {
    color: #971b93;
  }
  28.56% {
    color: #5123cd;
  }
  42.48% {
    color: #0030ff;
  }
  57.12% {
    color: #009393;
  }
  71.4% {
    color: #ff4f00;
  }
  85.68% {
    color: #ff2100;
  }
  100% {
    color: #d91c52;
  }
}
@media only screen and (max-width: 1200px) {
  .navBarMenuWrapper a {
    font-size: 16px;
  }
}

.AddCompany {
  margin-bottom: 15px;
}
.AddCompany > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}

.TableModalVerticalCenterChooseCompany tbody tr td:nth-child(3) {
  text-align: right;
}

.AddCompanyDetailHeader {
  position: relative;
}
.AddCompanyDetailHeader > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}
.AddCompanyDetailHeader .AddTopicButton {
  position: absolute;
  right: 0;
  top: 5px;
}

.AddJobHeader {
  position: relative;
}
.AddJobHeader > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}
.AddJobHeader .AddTopicButton {
  position: absolute;
  right: 0;
  top: 5px;
}

.TableModalVerticalCenterChooseJob tbody tr td:nth-child(3) {
  text-align: right;
}

.AddJobDetailHeader {
  position: relative;
}
.AddJobDetailHeader > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}
.AddJobDetailHeader .AddTopicButton {
  position: absolute;
  right: 0;
  top: 5px;
}
.AddJobSubDetail {
  position: relative;
}
.AddJobSubDetail button {
  position: absolute;
  top: -5px;
  right: 0;
}

.AdminPage {
  margin-top: 15px;
}

.AdminPage > h2 {
  padding: 15px;
  background-color: #4759bc;
  color: #fff;
}
.AdminPage .nav-item {
  cursor: pointer;
}

.LiveStreamSetting > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}

.activity_archive_topic {
  padding: 30px 0 20px 0;
  font-weight: 500;
  font-size: 45px;
}
.activity_archive_image_topic {
  width: 100%;
  border-radius: 40px;
}
.activity_archive_content_topic {
  padding-left: 20px;
}
.activity_archive_content_topic h2 {
  font-size: 32px;
  color: #ff1212;
}
.activity_archive_content_topic h4 a {
  text-decoration: none;
}
.activity_archive_content_topic h3 {
  color: #868686;
}
.activity_archive_old {
  margin-top: 15px;
}
.activity_archive_old img {
  width: auto;
  height: 200px;
}
.activity_archive_old_image1 {
  width: 100%;
  height: 210px;
  background: url(/static/media/2020.22752f90.png) no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image2 {
  width: 100%;
  height: 210px;
  background: url(/static/media/2019.1ace955f.jpg) no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image3 {
  width: 100%;
  height: 210px;
  background: url(/static/media/2018.0c47a29d.jpg) no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image4 {
  width: 100%;
  height: 210px;
  background: url(/static/media/2017.9f2508c8.jpg) no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image5 {
  width: 100%;
  height: 210px;
  background: url("/static/media/student archive.6d5ab336.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image6 {
  width: 100%;
  height: 210px;
  background: url("/static/media/exchange program.34e8a243.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image7 {
  width: 100%;
  height: 210px;
  background: url(/static/media/internship.1ed804ac.jpg) no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_content {
  margin-top: 20px;
}
.activity_archive_old_content h5 {
  color: #ff1215;
}
.activity_archive_topic_section {
  margin-top: 70px;
}
.activity_archive_topic_section h3 {
  font-weight: 600;
}

.position_abs_card_company_detail_banner {
  position: absolute;
  top: 62%;
  width: 100%;
  background-color: #f2f2f2;
}
.background_company_detail_banner {
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.card_company_detail_banner {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
  padding: 50px 0 50px 0;
}
.card_company_logo_detail {
  display: flex;
  width: 100%;
}
.card_company_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
}
.card_company_logo img {
  width: 220px;
  height: 120px;
  padding: 5px;
}
.card_company_detail {
  padding-left: 50px;
  border-left: 1px solid #000;
}
.card_company_detail h1 {
  font-size: 30px;
}
.card_company_view_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card_company_view_button a {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
  text-decoration: none;
}
.card_company_subscribe_button {
  align-self: center;
  border: 2px solid #0080f2;
  color: #0080f2;
  padding: 10px;
  border-radius: 45px;
}
.card_company_subscribe_button a {
  text-decoration: none;
  font-size: 25px;
}
@media only screen and (max-width: 991px) {
  .position_abs_card_company_detail_banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .card_company_detail_banner {
    flex-direction: column;
    align-items: center;
  }
  .card_company_logo_detail {
    flex-direction: column;
    align-items: center;
  }
  .card_company_logo {
    padding: 0px;
  }
  .card_company_detail {
    text-align: center;
    border-left: none;
    padding: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

.company_detail_contact_tab_section {
  background-color: #f7f7f7;
  padding: 20px 0 20px 0;
}
.company_detail_contact_tab .company_detail_contact_tab_header {
  display: flex;
}
.company_detail_contact_tab .company_detail_contact_tab_header > div {
  width: calc(100% / 3);
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  color: #acacac;
  cursor: pointer;
}
.company_detail_contact_tab .company_detail_contact_tab_header > div.active {
  color: #175a95;
}
.company_detail_contact_tab_indicator {
  position: relative;
  left: 0;
  height: 2px;
  top: 20px;
  background-color: #175a95;
  width: calc(calc(100% / 3) - 80px);
  transition: all 500ms ease-in-out;
}
.company_detail_contact_tab_body {
  margin-top: 50px;
  margin-bottom: 50px;
}
.company_detail_contact_tab_body > div {
  display: none;
  transform: scale(0.3);
  transition: opacity 500ms ease-in-out 0ms, transform 500ms ease-in-out 0ms;
}
.company_detail_contact_tab_body > div.active {
  display: block;
  color: #000;
  transform: scale(1);
}
.company_detail_contact_tab_body_content h3 {
  text-transform: uppercase;
  font-weight: 600;
}
.company_detail_contact_tab_body_content h4 {
  font-size: 32px;
  font-weight: 400;
}
.company_detail_paragraph {
  margin-top: 50px;
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .company_detail_contact_tab .company_detail_contact_tab_header > div {
    font-size: 15px;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
  .company_detail_contact_tab_body_content h3 {
    font-size: 20px;
  }
  .company_detail_contact_tab_body_content h4 {
    font-size: 15px;
  }
}

.card_job {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
  padding: 30px 0 30px 0;
}
.card_job_logo_detail {
  display: flex;
  width: 100%;
}
.card_job_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_job_logo img {
  width: 180px;
  height: 100px;
  padding: 5px;
  object-fit: cover;
}
.card_job_detail {
  margin-left: 40px;
}
.card_job_view_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card_job_view_button a {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .card_job {
    flex-direction: column;
    padding: 30px 0 0px 0;
  }
  .card_job_logo img {
    padding: 0px;
  }
  .card_job_view_button a {
    font-size: 30px;
    padding: 15px 0 15px 0;
  }
  .card_job_view_button {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .card_job_detail h1 {
    font-size: 16px;
  }
  .card_job_detail h3 {
    font-size: 14px;
  }
  .card_job_view_button a {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .card_job_detail h1 {
    font-size: 15px;
  }
  .card_job_detail h3 {
    font-size: 13px;
  }
  .card_job_view_button a {
    font-size: 16px;
  }
  .card_job_logo img {
    width: 90px;
    height: 50px;
  }
}

.RecruitmentCompanyDetailTopic {
  background-color: #f5f5f5;
  padding: 20px 0 20px 0;
}

.RecruitmentCompanyDetailTopicWrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentCompanyDetailTopicWrapper h1 {
  align-self: center;
}
.RecruitmentCompanyDetailTopicWrapper div {
  text-align: right;
}
.RecruitmentCompanyDetailTopicWrapper div h1 {
  color: #ff0f10;
}

.RecruitmentCompanyDetailTopicWrapper div h5 {
  color: #5b5a5a;
}
.RecruitmentCompanyJobDetailContact {
  background-color: #fafafa;
}
@media only screen and (max-width: 1200px) {
  .RecruitmentCompanyDetailTopicWrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}

.card_company {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin: 10px 0 10px 0;
}
.card_company_content {
  padding: 40px 20px 20px 40px;
}
.card_company_logo {
  width: 250px;
  height: 110px;
  object-fit: cover;
}
.card_company_content h1 {
  font-size: 40px;
  color: #000000;
}
.card_company_content h2 {
  font-size: 30px;
  font-weight: 500;
  color: #626262;
}
.card_company_link {
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
  .card_company_logo {
    width: 250px;
    height: 110px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .card_company_content h1 {
    font-size: 30px;
    color: #000000;
  }
  .card_company_content h2 {
    font-size: 20px;
    font-weight: 500;
    color: #626262;
  }
}
@media screen and (max-width: 375px) {
  .card_company_logo {
    width: 250px;
    height: 110px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .card_company_content h1 {
    font-size: 20px;
    color: #000000;
  }
  .card_company_content h2 {
    font-size: 15px;
    font-weight: 500;
    color: #626262;
  }
}

.there_more_company .there_more_company_header {
  padding: 40px 0 20px 0;
}
.there_more_company_header h1 {
  color: #0084f9;
}
@media only screen and (max-width: 991px) {
  .there_more_company_header {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}

.CardMenu {
  padding-top: 65px;
  padding-bottom: 70px;
}

.CardMenu h1 {
  font-size: 55px;
  font-weight: 600;
  color: #000;
}

.CardMenu h2 {
  font-size: 35px;
  color: #2d3691;
  font-family: "Maven Pro", sans-serif !important;
  font-weight: 600;
}

.CardMenuChoice {
  border-radius: 30px !important;
  box-shadow: 0px 0px 2px 0px;
}
.CardMenuChoice:hover {
  cursor: pointer;
  transform: translateY(3px);
  transition: transform 0.5s ease-in-out;
}
.CardMenuChoiceImg {
  height: 100%;
  border-radius: 30px 30px 0 0 !important;
}
.CardMenuBody .h5 {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 28px !important;
  color: #0047f5;
}
.CardMenuBody .h6 {
  margin-left: 15px;
  margin-top: 8px;
  font-size: 34px !important;
  font-weight: 600;
  color: #000;
}
.sectionCardMenu {
  margin-top: 50px !important;
}
.sectionCardMenu .col {
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .CardMenuHeader {
    text-align: center;
  }
  .CardMenuHeader h1 {
    font-size: 40px;
  }
  .CardMenuHeader h2 {
    font-size: 25px;
  }
  .CardMenuBody .card-title {
    font-size: 20px !important;
  }
  .CardMenuBody .card-subtitle {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 768px) {
  .CardMenuHeader {
    text-align: center;
  }
  .CardMenuHeader h1 {
    font-size: 40px;
  }
  .CardMenuHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .CardMenuHeader h1 {
    font-size: 30px;
  }
  .CardMenuHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

.CarouselWrapper {
  width: 100%;
}

.FacebookLive {
  background-color: #f5f5f7;
}
.FacebookLive h1 {
  padding-top: 80px;
  color: #ff001d !important;
  font-size: 40px;
}
.FacebookLive h2 {
  padding-top: 80px;
  color: #515154 !important;
  font-size: 60px;
}
.home_live_video_background {
  height: 735px;
}
.home_live_video_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-image: url("../../../Images/Banner\ TNI\ Job\ Fair-02.jpg"); */
  background-color: #000;
  background-size: cover;
  max-height: 1000px;
}
.home_iframe_live_video_container {
  max-height: 700px;
}
.home_live_video {
  width: 100%;
}
.home_live_video_mobile {
  display: none;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.home_live_video_mobile iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1270px) {
  .home_iframe_live_video_container {
    display: none;
  }
  .home_live_video_mobile {
    display: block;
  }
}
@media only screen and (max-width: 992px) {
  .FacebookLiveHeader {
    text-align: center;
  }
  .FacebookLiveHeader h1 {
    font-size: 40px;
  }
  .FacebookLiveHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .FacebookLiveHeader {
    text-align: center;
  }
  .FacebookLiveHeader h1 {
    font-size: 40px;
  }
  .FacebookLiveHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .FacebookLiveHeader h1 {
    font-size: 30px;
  }
  .FacebookLiveHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

/* @media screen and (max-width: 1200px) {
    .banner_live_video {
      height: 490px;
    }
  }
  @media screen and (max-width: 992px) {
    .banner_live_video {
      height: 360px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner_live_video {
      height: 300px;
    }
  }
  
  @media screen and (max-width: 577px) {
    .banner_live_video {
      height: 250px;
    }
  }
   */

.TniJobFairTimeLineTopic h1 {
  padding-top: 60px;
  font-size: 60px;
  color: #0067c3;
}
.TniJobFairTimeLineTopic h1 span {
  font-weight: 400;
  color: #2c9cff;
}
.CardMenuChoice iframe {
  border-radius: 30px 30px 0 0;
}
.sectionCardMenu .CardMenuChoice {
  margin-bottom: 25px;
}
@media only screen and (max-width: 992px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

.RecruitmentJobFromSameCompanyCompanyTopic {
  margin-top: 40px;
  background-color: #f5f5f5;
  padding: 20px 0 20px 0;
}

.RecruitmentJobFromSameCompanyCompanyTopicWrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper h1 {
  align-self: center;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper div {
  text-align: right;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper div h1 {
  color: #ff0f10;
}

.RecruitmentJobFromSameCompanyCompanyTopicWrapper div h5 {
  color: #5b5a5a;
}
.RecruitmentJobFromSameCompany {
  background-color: #fafafa;
}
@media only screen and (max-width: 992px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .RecruitmentJobFromSameCompanyCompanyTopicWrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}

.job_detail_header {
  padding: 60px 0 40px 0;
}
.job_detail_header h1 {
  font-size: 45px;
  text-transform: uppercase;
}
.job_detail_header h2 {
  color: #ff4848;
}
.job_from_company_logo div img {
  width: 100px;
}
.job_from_company_name h6 a {
  text-decoration: none;
}
.job_from_company {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 15px 0 15px 0;
}
.job_from_company_logo {
  display: flex;
}
.job_from_company_name {
  align-self: center;
  margin-left: 15px;
}
.job_from_company_tel {
  align-self: center;
  font-size: 28px;
}
.job_company_detail {
  margin-top: 50px;
}
.job_company_detail h2 {
  font-weight: 600;
}
.job_company_detail ul li {
  font-size: 25px;
  font-weight: 500;
}
.job_company_detail_expand h2 {
  margin-bottom: 20px;
}
.process_register {
  padding: 20px 0 20px 0;
  background-color: #f7f7f7;
  border-radius: 20px;
  font-size: 25px;
  font-weight: 500;
}
.process_register a {
  color: #000;
}
.process_register div {
  padding: 20px;
}
.process_register div:nth-child(2) svg {
  color: #0076aa;
}
.moreBenefit {
  text-decoration: none;
}
.process_register a {
  text-decoration: none;
}
@media only screen and (max-width: 1200px) {
  .job_detail_header {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job_from_company_logo div img {
    width: 225px;
  }
  .job_detail_header h1 {
    font-size: 30px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_from_company {
    flex-direction: column;
    text-align: center;
  }
  .job_from_company_logo {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .job_detail_header {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job_detail_header h1 {
    font-size: 40px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_detail_header h2 {
    font-size: 15px;
  }
  .job_from_company {
    flex-direction: column;
    text-align: center;
  }
  .job_from_company_logo {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .job_detail_header {
    text-align: center;
  }
  .job_detail_header h1 {
    font-size: 40px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_detail_requirement {
    order: 1;
  }
  .job_detail_welfare {
    order: 2;
  }
  .job_detail_qualification {
    order: 3;
  }
}
@media screen and (max-width: 500px) {
  .job_detail_header h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 375px) {
}

.banner_live_video_background {
  height: 735px;
}
.banner_live_video_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/background_live.c8bd0aa6.png);
  background-size: cover;
  max-height: 540px;
}
.iframe_live_video_container {
  max-height: 540px;
}
.banner_live_video {
  width: 960px;
}
.banner_live_video_mobile {
  display: none;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.banner_live_video_mobile iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1202px) {
  .iframe_live_video_container {
    display: none;
  }
  .banner_live_video_mobile {
    display: block;
  }
}
/* @media screen and (max-width: 1200px) {
    .banner_live_video {
      height: 490px;
    }
  }
  @media screen and (max-width: 992px) {
    .banner_live_video {
      height: 360px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner_live_video {
      height: 300px;
    }
  }
  
  @media screen and (max-width: 577px) {
    .banner_live_video {
      height: 250px;
    }
  }
   */

.show_more {
  text-align: center;
}
.show_more a {
  font-size: 32px;
  font-weight: 600;
  color: #0067c3;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .show_more a {
    font-size: 16px;
  }
}

.partner_finding_title {
  font-size: 60px;
  color: #0067c3;
}
.partner_finding_subtitle {
  color: #515154;
}
.show_more_card_partner_finding {
  padding: 30px 0 40px 0;
}
.partner_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}
@media screen and (max-width: 1202px) {
  .partner_header_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .partner_header_wrapper {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .partner_header_wrapper h1 {
    font-size: 40px;
  }
  .partner_header_wrapper h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .partner_header_wrapper {
    text-align: center;
  }
  .partner_header_wrapper h1 {
    font-size: 40px;
  }
  .partner_header_wrapper h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .partner_header_wrapper h1 {
    font-size: 30px;
  }
  .partner_header_wrapper h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

.show_less {
  text-align: center;
}
.show_less a {
  font-size: 32px;
  font-weight: 600;
  color: #0067c3;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .show_less a {
    font-size: 16px;
  }
}

.tabRecruitmentSection {
  background-color: #fafafa;
}
.RecruitmentFindingTopic {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fafafa;
}

.RecruitmentFindingDetail {
  background-color: #f7f7f7;
}
.LogoAndDetailJob {
  display: flex;
  flex-direction: row;

  padding: 30px 0 30px 0;
}
.LogoAndDetailJob img {
  width: 180px;
  padding: 5px;
}
.DetailJobSection {
  margin-left: 40px;
}
.LogoAndDetailJob_Wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
}
.LogoAndDetailJob_Wrapper {
  align-items: center;
}
.LogoAndDetailJob_Wrapper span {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
}
.RecruitmentFindingTopic_Wrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentFindingTopic_Wrapper h2 {
  display: flex;
  align-self: center;
  font-size: 35px;
  color: #6a6a6a;
}
/*--Tab--*/
.tabRecruitment {
  border-bottom: 1px solid #cecece;
}
.tabRecruitment .tabRecruitmentHeader {
  height: 150px;
  display: flex;
  align-items: center;
}
.tabRecruitment .tabRecruitmentHeader > div {
  color: #989898;
  width: calc(100% / 3);
  cursor: pointer;
  outline: none;
}
.tabRecruitment .tabRecruitmentHeader > div.active {
  color: #175a95;
}
.tabRecruitment .tabRecruitmentIndicator {
  position: relative;
  width: calc(calc(100% / 3) - 70px);
  height: 3px;
  background-color: #175a95;
  left: 0px;
  top: -20px;
  transition: all 500ms ease-in-out;
}
.tabRecruitment .tabRecruitmentBody {
  position: relative;
}
.tabRecruitment .tabRecruitmentBody > div {
  position: absolute;
  top: -200%;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms ease-in-out 0ms, transform 500ms ease-in-out 0ms;
}
.tabRecruitment .tabRecruitmentBody > div.active {
  top: 0px;
  opacity: 1;
  transform: scale(1);
}
.tabRecruitmentIcon {
  display: flex;
  align-items: center;
}
.tabRecruitmentContent svg {
  position: absolute;
  font-size: 45px;
}
/*-------SearchJobBox----------*/
.searchJob {
  position: relative;
}
.searchJobIconWrapper {
  position: absolute;
  top: 15px;
  left: 13px;
}
.searchJobIconWrapper svg {
  font-size: 25px;
  color: #0084f9;
}
.searchJobInput {
  font-size: 25px;
  padding: 10px 30px 10px 50px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 2px 1px #000;
}
.searchJobInput:-ms-input-placeholder {
  color: #0084f9;
}
.searchJobInput::placeholder {
  color: #0084f9;
}
@media screen and (max-width: 1202px) {
  .tabRecruitmentContent h3 {
    font-size: 20px;
  }
  .tabRecruitmentContent h4 {
    font-size: 21px;
  }
  .tabRecruitmentContent svg {
    font-size: 30px;
  }
  .RecruitmentFindingTopic_Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 991px) {
  .tabRecruitmentContent h3 {
    font-size: 15px;
  }
  .tabRecruitmentContent h4 {
    font-size: 15px;
  }

  .tabRecruitmentIcon {
    padding: 0px !important;
  }
  .tabRecruitment .tabRecruitmentIndicator {
    width: calc(calc(100% / 3) - 30px);
  }
  .tabRecruitmentContent svg {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .tabRecruitmentContent h3 {
    font-size: 14px;
  }
  .tabRecruitmentContent h4 {
    font-size: 13px;
  }
  .tabRecruitmentContent svg {
    font-size: 20px;
  }
  .tabRecruitmentIcon {
    padding: 0px !important;
    width: 0px;
  }
  .tabRecruitment .tabRecruitmentIndicator {
    width: calc(calc(100% / 3) - 30px);
  }

  .tabRecruitmentContent {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .searchJobInput {
    width: 300px;
    font-size: 20px;
  }
  .tabRecruitmentContent svg {
    font-size: 15px;
  }
  .RecruitmentFindingTopic_Wrapper {
    flex-direction: column;
    align-items: center;
  }
  .RecruitmentFindingTopic_Wrapper h1 {
    font-size: 20px;
  }
  .searchJobIconWrapper svg {
    font-size: 15px;
  }
  .tabRecruitmentIcon {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .searchJobInput {
    width: 200px;
    font-size: 15px;
  }
  .searchJobIconWrapper {
    top: 10px;
    left: 20px;
  }
  .searchJobIconWrapper svg {
    font-size: 15px;
  }
}

