.RecruitmentJobFromSameCompanyCompanyTopic {
  margin-top: 40px;
  background-color: #f5f5f5;
  padding: 20px 0 20px 0;
}

.RecruitmentJobFromSameCompanyCompanyTopicWrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper h1 {
  align-self: center;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper div {
  text-align: right;
}
.RecruitmentJobFromSameCompanyCompanyTopicWrapper div h1 {
  color: #ff0f10;
}

.RecruitmentJobFromSameCompanyCompanyTopicWrapper div h5 {
  color: #5b5a5a;
}
.RecruitmentJobFromSameCompany {
  background-color: #fafafa;
}
@media only screen and (max-width: 992px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .RecruitmentJobFromSameCompanyCompanyTopicWrapper {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}
