.FacebookLive {
  background-color: #f5f5f7;
}
.FacebookLive h1 {
  padding-top: 80px;
  color: #ff001d !important;
  font-size: 40px;
}
.FacebookLive h2 {
  padding-top: 80px;
  color: #515154 !important;
  font-size: 60px;
}
.home_live_video_background {
  height: 735px;
}
.home_live_video_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-image: url("../../../Images/Banner\ TNI\ Job\ Fair-02.jpg"); */
  background-color: #000;
  background-size: cover;
  max-height: 1000px;
}
.home_iframe_live_video_container {
  max-height: 700px;
}
.home_live_video {
  width: 100%;
}
.home_live_video_mobile {
  display: none;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.home_live_video_mobile iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1270px) {
  .home_iframe_live_video_container {
    display: none;
  }
  .home_live_video_mobile {
    display: block;
  }
}
@media only screen and (max-width: 992px) {
  .FacebookLiveHeader {
    text-align: center;
  }
  .FacebookLiveHeader h1 {
    font-size: 40px;
  }
  .FacebookLiveHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .FacebookLiveHeader {
    text-align: center;
  }
  .FacebookLiveHeader h1 {
    font-size: 40px;
  }
  .FacebookLiveHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .FacebookLiveHeader h1 {
    font-size: 30px;
  }
  .FacebookLiveHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}

/* @media screen and (max-width: 1200px) {
    .banner_live_video {
      height: 490px;
    }
  }
  @media screen and (max-width: 992px) {
    .banner_live_video {
      height: 360px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner_live_video {
      height: 300px;
    }
  }
  
  @media screen and (max-width: 577px) {
    .banner_live_video {
      height: 250px;
    }
  }
   */
