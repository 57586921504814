.AdminPage {
  margin-top: 15px;
}

.AdminPage > h2 {
  padding: 15px;
  background-color: #4759bc;
  color: #fff;
}
.AdminPage .nav-item {
  cursor: pointer;
}
