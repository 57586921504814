.banner_live_video_background {
  height: 735px;
}
.banner_live_video_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../../../Images/background_live.png");
  background-size: cover;
  max-height: 540px;
}
.iframe_live_video_container {
  max-height: 540px;
}
.banner_live_video {
  width: 960px;
}
.banner_live_video_mobile {
  display: none;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.banner_live_video_mobile iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1202px) {
  .iframe_live_video_container {
    display: none;
  }
  .banner_live_video_mobile {
    display: block;
  }
}
/* @media screen and (max-width: 1200px) {
    .banner_live_video {
      height: 490px;
    }
  }
  @media screen and (max-width: 992px) {
    .banner_live_video {
      height: 360px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner_live_video {
      height: 300px;
    }
  }
  
  @media screen and (max-width: 577px) {
    .banner_live_video {
      height: 250px;
    }
  }
   */
