.RecruitmentCompanyDetailTopic {
  background-color: #f5f5f5;
  padding: 20px 0 20px 0;
}

.RecruitmentCompanyDetailTopicWrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentCompanyDetailTopicWrapper h1 {
  align-self: center;
}
.RecruitmentCompanyDetailTopicWrapper div {
  text-align: right;
}
.RecruitmentCompanyDetailTopicWrapper div h1 {
  color: #ff0f10;
}

.RecruitmentCompanyDetailTopicWrapper div h5 {
  color: #5b5a5a;
}
.RecruitmentCompanyJobDetailContact {
  background-color: #fafafa;
}
@media only screen and (max-width: 1200px) {
  .RecruitmentCompanyDetailTopicWrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}
