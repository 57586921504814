.activity_archive_topic {
  padding: 30px 0 20px 0;
  font-weight: 500;
  font-size: 45px;
}
.activity_archive_image_topic {
  width: 100%;
  border-radius: 40px;
}
.activity_archive_content_topic {
  padding-left: 20px;
}
.activity_archive_content_topic h2 {
  font-size: 32px;
  color: #ff1212;
}
.activity_archive_content_topic h4 a {
  text-decoration: none;
}
.activity_archive_content_topic h3 {
  color: #868686;
}
.activity_archive_old {
  margin-top: 15px;
}
.activity_archive_old img {
  width: auto;
  height: 200px;
}
.activity_archive_old_image1 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/2020.png") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image2 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/2019.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image3 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/2018.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image4 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/2017.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image5 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/student\ archive.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image6 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/exchange\ program.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_image7 {
  width: 100%;
  height: 210px;
  background: url("../../../Images/internship.jpg") no-repeat;
  background-size: cover;
  border-radius: 40px;
}
.activity_archive_old_content {
  margin-top: 20px;
}
.activity_archive_old_content h5 {
  color: #ff1215;
}
.activity_archive_topic_section {
  margin-top: 70px;
}
.activity_archive_topic_section h3 {
  font-weight: 600;
}
