.card_job {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
  padding: 30px 0 30px 0;
}
.card_job_logo_detail {
  display: flex;
  width: 100%;
}
.card_job_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_job_logo img {
  width: 180px;
  height: 100px;
  padding: 5px;
  object-fit: cover;
}
.card_job_detail {
  margin-left: 40px;
}
.card_job_view_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card_job_view_button a {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .card_job {
    flex-direction: column;
    padding: 30px 0 0px 0;
  }
  .card_job_logo img {
    padding: 0px;
  }
  .card_job_view_button a {
    font-size: 30px;
    padding: 15px 0 15px 0;
  }
  .card_job_view_button {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .card_job_detail h1 {
    font-size: 16px;
  }
  .card_job_detail h3 {
    font-size: 14px;
  }
  .card_job_view_button a {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .card_job_detail h1 {
    font-size: 15px;
  }
  .card_job_detail h3 {
    font-size: 13px;
  }
  .card_job_view_button a {
    font-size: 16px;
  }
  .card_job_logo img {
    width: 90px;
    height: 50px;
  }
}
