.position_abs_card_company_detail_banner {
  position: absolute;
  top: 62%;
  width: 100%;
  background-color: #f2f2f2;
}
.background_company_detail_banner {
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.card_company_detail_banner {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
  padding: 50px 0 50px 0;
}
.card_company_logo_detail {
  display: flex;
  width: 100%;
}
.card_company_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
}
.card_company_logo img {
  width: 220px;
  height: 120px;
  padding: 5px;
}
.card_company_detail {
  padding-left: 50px;
  border-left: 1px solid #000;
}
.card_company_detail h1 {
  font-size: 30px;
}
.card_company_view_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card_company_view_button a {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
  text-decoration: none;
}
.card_company_subscribe_button {
  align-self: center;
  border: 2px solid #0080f2;
  color: #0080f2;
  padding: 10px;
  border-radius: 45px;
}
.card_company_subscribe_button a {
  text-decoration: none;
  font-size: 25px;
}
@media only screen and (max-width: 991px) {
  .position_abs_card_company_detail_banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .card_company_detail_banner {
    flex-direction: column;
    align-items: center;
  }
  .card_company_logo_detail {
    flex-direction: column;
    align-items: center;
  }
  .card_company_logo {
    padding: 0px;
  }
  .card_company_detail {
    text-align: center;
    border-left: none;
    padding: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}
