.show_more {
  text-align: center;
}
.show_more a {
  font-size: 32px;
  font-weight: 600;
  color: #0067c3;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  .show_more a {
    font-size: 16px;
  }
}
