.there_more_company .there_more_company_header {
  padding: 40px 0 20px 0;
}
.there_more_company_header h1 {
  color: #0084f9;
}
@media only screen and (max-width: 991px) {
  .there_more_company_header {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}
