.card_company {
  background-color: #f7f7f7;
  border-radius: 15px;
  margin: 10px 0 10px 0;
}
.card_company_content {
  padding: 40px 20px 20px 40px;
}
.card_company_logo {
  width: 250px;
  height: 110px;
  object-fit: cover;
}
.card_company_content h1 {
  font-size: 40px;
  color: #000000;
}
.card_company_content h2 {
  font-size: 30px;
  font-weight: 500;
  color: #626262;
}
.card_company_link {
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
  .card_company_logo {
    width: 250px;
    height: 110px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .card_company_content h1 {
    font-size: 30px;
    color: #000000;
  }
  .card_company_content h2 {
    font-size: 20px;
    font-weight: 500;
    color: #626262;
  }
}
@media screen and (max-width: 375px) {
  .card_company_logo {
    width: 250px;
    height: 110px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .card_company_content h1 {
    font-size: 20px;
    color: #000000;
  }
  .card_company_content h2 {
    font-size: 15px;
    font-weight: 500;
    color: #626262;
  }
}
