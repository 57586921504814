.AddJobDetailHeader {
  position: relative;
}
.AddJobDetailHeader > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}
.AddJobDetailHeader .AddTopicButton {
  position: absolute;
  right: 0;
  top: 5px;
}
.AddJobSubDetail {
  position: relative;
}
.AddJobSubDetail button {
  position: absolute;
  top: -5px;
  right: 0;
}
