/*Font Thai Kanit*/
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Maven+Pro&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*Font English Roboto*/
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Maven+Pro&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Lobster", cursive;
  font-family: "Maven Pro", sans-serif;
  font-family: "Prompt", sans-serif;
}

body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background-color: #f7f7f7;
}
.btn-primary {
  color: #fff;
  background-color: #341e97 !important;
  border-color: #341e97 !important;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1335px !important;
}

@media only screen and (max-width: 1200px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 960px !important;
  }
}
@media only screen and (max-width: 991px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 720px !important;
  }
}
@media only screen and (max-width: 767px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 540px !important;
  }
}
@media only screen and (max-width: 575px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 575px !important;
  }
}
