.tabRecruitmentSection {
  background-color: #fafafa;
}
.RecruitmentFindingTopic {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fafafa;
}

.RecruitmentFindingDetail {
  background-color: #f7f7f7;
}
.LogoAndDetailJob {
  display: flex;
  flex-direction: row;

  padding: 30px 0 30px 0;
}
.LogoAndDetailJob img {
  width: 180px;
  padding: 5px;
}
.DetailJobSection {
  margin-left: 40px;
}
.LogoAndDetailJob_Wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid #cecece;
}
.LogoAndDetailJob_Wrapper {
  align-items: center;
}
.LogoAndDetailJob_Wrapper span {
  color: #0084f9;
  font-size: 35px;
  font-weight: 600;
}
.RecruitmentFindingTopic_Wrapper {
  display: flex;
  justify-content: space-between;
}
.RecruitmentFindingTopic_Wrapper h2 {
  display: flex;
  align-self: center;
  font-size: 35px;
  color: #6a6a6a;
}
/*--Tab--*/
.tabRecruitment {
  border-bottom: 1px solid #cecece;
}
.tabRecruitment .tabRecruitmentHeader {
  height: 150px;
  display: flex;
  align-items: center;
}
.tabRecruitment .tabRecruitmentHeader > div {
  color: #989898;
  width: calc(100% / 3);
  cursor: pointer;
  outline: none;
}
.tabRecruitment .tabRecruitmentHeader > div.active {
  color: #175a95;
}
.tabRecruitment .tabRecruitmentIndicator {
  position: relative;
  width: calc(calc(100% / 3) - 70px);
  height: 3px;
  background-color: #175a95;
  left: 0px;
  top: -20px;
  transition: all 500ms ease-in-out;
}
.tabRecruitment .tabRecruitmentBody {
  position: relative;
}
.tabRecruitment .tabRecruitmentBody > div {
  position: absolute;
  top: -200%;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms ease-in-out 0ms, transform 500ms ease-in-out 0ms;
}
.tabRecruitment .tabRecruitmentBody > div.active {
  top: 0px;
  opacity: 1;
  transform: scale(1);
}
.tabRecruitmentIcon {
  display: flex;
  align-items: center;
}
.tabRecruitmentContent svg {
  position: absolute;
  font-size: 45px;
}
/*-------SearchJobBox----------*/
.searchJob {
  position: relative;
}
.searchJobIconWrapper {
  position: absolute;
  top: 15px;
  left: 13px;
}
.searchJobIconWrapper svg {
  font-size: 25px;
  color: #0084f9;
}
.searchJobInput {
  font-size: 25px;
  padding: 10px 30px 10px 50px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 2px 1px #000;
}
.searchJobInput::placeholder {
  color: #0084f9;
}
@media screen and (max-width: 1202px) {
  .tabRecruitmentContent h3 {
    font-size: 20px;
  }
  .tabRecruitmentContent h4 {
    font-size: 21px;
  }
  .tabRecruitmentContent svg {
    font-size: 30px;
  }
  .RecruitmentFindingTopic_Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 991px) {
  .tabRecruitmentContent h3 {
    font-size: 15px;
  }
  .tabRecruitmentContent h4 {
    font-size: 15px;
  }

  .tabRecruitmentIcon {
    padding: 0px !important;
  }
  .tabRecruitment .tabRecruitmentIndicator {
    width: calc(calc(100% / 3) - 30px);
  }
  .tabRecruitmentContent svg {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .tabRecruitmentContent h3 {
    font-size: 14px;
  }
  .tabRecruitmentContent h4 {
    font-size: 13px;
  }
  .tabRecruitmentContent svg {
    font-size: 20px;
  }
  .tabRecruitmentIcon {
    padding: 0px !important;
    width: 0px;
  }
  .tabRecruitment .tabRecruitmentIndicator {
    width: calc(calc(100% / 3) - 30px);
  }

  .tabRecruitmentContent {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .searchJobInput {
    width: 300px;
    font-size: 20px;
  }
  .tabRecruitmentContent svg {
    font-size: 15px;
  }
  .RecruitmentFindingTopic_Wrapper {
    flex-direction: column;
    align-items: center;
  }
  .RecruitmentFindingTopic_Wrapper h1 {
    font-size: 20px;
  }
  .searchJobIconWrapper svg {
    font-size: 15px;
  }
  .tabRecruitmentIcon {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .searchJobInput {
    width: 200px;
    font-size: 15px;
  }
  .searchJobIconWrapper {
    top: 10px;
    left: 20px;
  }
  .searchJobIconWrapper svg {
    font-size: 15px;
  }
}
