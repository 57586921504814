.AddJobHeader {
  position: relative;
}
.AddJobHeader > h4 {
  padding: 10px;
  background-color: #4759bc;
  color: #fff;
}
.AddJobHeader .AddTopicButton {
  position: absolute;
  right: 0;
  top: 5px;
}
