.footer {
  margin-top: 80px;
  padding: 30px 0 30px 0;
  background-color: #f5f5f5;
}
.footer_wrapper {
  padding: 30px 0 30px 0;
}
.footer img {
  width: 200px;
  margin: 0 auto;
}
.footer_wrapper h5 {
  font-size: 16px;
  color: #78706f;
}
.sub_footer_detail {
  margin-top: 24px;
}
.icon_and_copyright {
  display: flex;
}
.icon_footer {
  font-size: 28px;
}
.icon_footer svg {
  margin-right: 15px;
}
.icon_footer svg:nth-child(1) {
  color: #1977f3;
}
.icon_and_copyright h5 {
  align-self: center;
  margin: -5px 0 0 5px;
}
.line_footer {
  width: 2px;
  height: 15px;
  background-color: #7392bb;
  margin-top: 10px;
}
.footer_detail {
  border-left: 1px solid #d3d3d3;
}
.logo_footer {
  text-align: center;
}
.footer_detail_wrapper {
  margin-left: 15px;
}
@media screen and (max-width: 1270px) {
}
@media only screen and (max-width: 991px) {
  .logo_footer {
    margin-bottom: 20px;
  }
  .footer_wrapper {
    text-align: center;
  }
  .footer_detail {
    border-left: none;
  }
  .icon_and_copyright {
    display: block;
  }
  .icon_and_copyright .icon_footer {
    margin-bottom: 5px;
  }
  .line_footer {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 375px) {
}
