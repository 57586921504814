.navBarMenu {
  font-size: 22px;
  font-weight: 600;
}
.navBarMenu .nav-link {
  padding: 20px 24px 20px 24px !important;
}
.navBarMenu .logo {
  border-left: 6px solid #2d3691;
  padding-left: 14px;
  width: 225px;
}
.tnijobfairmenu {
  animation: color-change 2s infinite !important;
}
@keyframes color-change {
  14.28% {
    color: #971b93;
  }
  28.56% {
    color: #5123cd;
  }
  42.48% {
    color: #0030ff;
  }
  57.12% {
    color: #009393;
  }
  71.4% {
    color: #ff4f00;
  }
  85.68% {
    color: #ff2100;
  }
  100% {
    color: #d91c52;
  }
}
@media only screen and (max-width: 1200px) {
  .navBarMenuWrapper a {
    font-size: 16px;
  }
}
