.TniJobFairTimeLineTopic h1 {
  padding-top: 60px;
  font-size: 60px;
  color: #0067c3;
}
.TniJobFairTimeLineTopic h1 span {
  font-weight: 400;
  color: #2c9cff;
}
.CardMenuChoice iframe {
  border-radius: 30px 30px 0 0;
}
.sectionCardMenu .CardMenuChoice {
  margin-bottom: 25px;
}
@media only screen and (max-width: 992px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .TniJobFairTimeLineTopicHeader {
    text-align: center;
  }
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 40px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .TniJobFairTimeLineTopicHeader h1 {
    font-size: 30px;
  }
  .TniJobFairTimeLineTopicHeader h2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 375px) {
}
