.job_detail_header {
  padding: 60px 0 40px 0;
}
.job_detail_header h1 {
  font-size: 45px;
  text-transform: uppercase;
}
.job_detail_header h2 {
  color: #ff4848;
}
.job_from_company_logo div img {
  width: 100px;
}
.job_from_company_name h6 a {
  text-decoration: none;
}
.job_from_company {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 15px 0 15px 0;
}
.job_from_company_logo {
  display: flex;
}
.job_from_company_name {
  align-self: center;
  margin-left: 15px;
}
.job_from_company_tel {
  align-self: center;
  font-size: 28px;
}
.job_company_detail {
  margin-top: 50px;
}
.job_company_detail h2 {
  font-weight: 600;
}
.job_company_detail ul li {
  font-size: 25px;
  font-weight: 500;
}
.job_company_detail_expand h2 {
  margin-bottom: 20px;
}
.process_register {
  padding: 20px 0 20px 0;
  background-color: #f7f7f7;
  border-radius: 20px;
  font-size: 25px;
  font-weight: 500;
}
.process_register a {
  color: #000;
}
.process_register div {
  padding: 20px;
}
.process_register div:nth-child(2) svg {
  color: #0076aa;
}
.moreBenefit {
  text-decoration: none;
}
.process_register a {
  text-decoration: none;
}
@media only screen and (max-width: 1200px) {
  .job_detail_header {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job_from_company_logo div img {
    width: 225px;
  }
  .job_detail_header h1 {
    font-size: 30px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_from_company {
    flex-direction: column;
    text-align: center;
  }
  .job_from_company_logo {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .job_detail_header {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job_detail_header h1 {
    font-size: 40px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_detail_header h2 {
    font-size: 15px;
  }
  .job_from_company {
    flex-direction: column;
    text-align: center;
  }
  .job_from_company_logo {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .job_detail_header {
    text-align: center;
  }
  .job_detail_header h1 {
    font-size: 40px;
  }
  .job_detail_header h2 {
    font-size: 25px;
  }
  .job_detail_requirement {
    order: 1;
  }
  .job_detail_welfare {
    order: 2;
  }
  .job_detail_qualification {
    order: 3;
  }
}
@media screen and (max-width: 500px) {
  .job_detail_header h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 375px) {
}
